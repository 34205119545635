import React, { useState, useEffect, useRef } from 'react'
import { OutboundLink } from "gatsby-plugin-google-analytics"
import ArrowForward from "../Icons/ArrowForward";

const CTABar = ({ text, linktext, link, label }) => {
  const [hidden, setHidden] = useState(false);
  const [fixed, setFixed] = useState(false);
  const ctabarRef = useRef(null);

  useEffect(() => {

    const handleScroll = () => {
      const h = ctabarRef.current ? ctabarRef.current.offsetHeight : 0;
      if (window.pageYOffset > 0) {
        document.body.style.marginTop = `${h}px`;
        setFixed(true);
      } else {
        document.body.style.marginTop = `0px`;
        setFixed(false);
      }
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <div ref={ctabarRef} className={`ctabar${hidden ? " ctabar--closed" : ""}${fixed ? " ctabar--fixed" : ""}`}>
      <div className="ctabar__inner">
        <p className="ctabar__text">
          {text}
        </p>
        <OutboundLink className="ctabar__button" href={link} rel="nofollow">{linktext} <ArrowForward /></OutboundLink>
      </div>
      <button className="ctabar__close" aria-label="Close call to action" onClick={() => setHidden(true)}>&times;</button>
    </div>
  )
}

export default CTABar
