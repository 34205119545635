import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import Parallax from 'react-rellax'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Prefooter from '../components/prefooter'
import SectionHeader from '../components/section-header'
import PageHeader from '../components/page-header'
import Card from '../components/card'
import LinkCard from '../components/link-card'
import NewsletterSignup from '../components/newsletter-signup'
import Services from '../components/services'
import Block from '../components/waypoint-block'
import VideoBlock from '../components/waypoint-video'
import ColorSwitch from '../components/waypoint-color-switch'
import ImageSlider from '../components/image-slider'
import Drift from '../components/waypoint-drift'

import DownArrow from '../components/Icons/ArrowDown'
import ForwardArrow from '../components/Icons/ArrowForward'

import CTABar from '../components/ctabar'

class ClutchPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: 'All',
      showAllClients: false,
    }

    this.links = this.props.data.allPrismicRecentLinks.edges[0].node.data.links
    this.clients = this.props.data.allPrismicClients.edges.map(
      item => item.node
    )
    this.photos = this.props.data.allPrismicPhotoGallery.edges[0].node.data.photos.map(
      item => ({
        slide: {
          url: item.photo.url,
          alt: item.name,
        },
      })
    )
    this.clientFilters = this.clients
      .map(client => client.data.company_type.split(/,\s*/))
      .reduce((acc, val) => acc.concat(val), ['All'])
      .filter((item, index, arr) => arr.lastIndexOf(item) === index)
      .sort()
  }

  handleVideoChange = ({ currentPosition }) => {
    const video = document.getElementById('hero-video')
    currentPosition === 'above' && video.classList.add('hideVideo')
    currentPosition === 'below' && video.classList.remove('hideVideo')
  }

  renderClients = clientList => {
    if (clientList.length < 1) {
      return <p>None found in this type</p>
    }

    let clients = clientList.slice()
    if (this.state.filter !== 'All') {
      clients = clients.filter(client =>
        client.data.company_type.includes(this.state.filter)
      )
    }

    const displayClients = clients.map((client, index) => (
      <Card
        key={index}
        classes={client.data.company_type
          .toLowerCase()
          .split(', ')
          .join(' ')}
      >
        <img
          className="client-logo"
          src={client.data.logo.url}
          alt={client.data.name}
        />
        <div className="color-secondary small">
          {client.data.tags}
          &nbsp;
        </div>
        <h5>{client.data.lede}</h5>
        <p>{client.data.description}</p>
        {/* Sometimes, Prismic sends empty data. Check for that. */}
        {client.data.urls.length > 0 && client.data.urls[0].url_text !== null && (
          <div className="client-urls">
            {client.data.urls.map((link, index) => {
              return (
                <React.Fragment key={index}>
                  {index !== 0 && <span>|</span>}
                  {link.url !== null ? (
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.url_text}
                    </a>
                  ) : (
                    link.url_text
                  )}
                </React.Fragment>
              )
            })}
          </div>
        )}
      </Card>
    ))

    return (
      <div className="cards grid grid--3col">
        {displayClients}
        {!this.state.showAllClients && clients.length > 6 && (
          <div className="c-client-cards__view-all-button">
            <button
              onClick={() => {
                this.setState({ showAllClients: !this.state.showAllClients })
              }}
            >
              View all recent projects
            </button>
          </div>
        )}
      </div>
    )
  }

  renderLinks = links => {
    if (links.length < 1) {
      return <p>No links found.</p>
    }

    return links.map((link, index) => (
      <Card key={index}>
        <a href={link.url}>
          <picture>
            <source
              sizes="(min-width: 1220px) calc(32.36vw - 89px), (min-width: 780px) calc(48.1vw - 90px), calc(100vw - 62px)"
              srcSet={`${link.photo.link_300_1x.url} 300w,
                ${link.photo.link_500_1x.url} 500w,
                ${link.photo.link_300_2x.url} 600w,
                ${link.photo.link_700_1x.url} 700w,
                ${link.photo.link_500_2x.url} 1000w,
                ${link.photo.link_700_2x.url} 1400w,`}
            />
            <img src={link.photo.url} alt={link.description} />
          </picture>
          <p>{link.source}</p>
          <p>{link.description}</p>
          <ForwardArrow />
        </a>
      </Card>
    ))
  }

  render() {
    return (
      <>
        <CTABar
          link="https://calendly.com/dougvs/talk-with-versett"
          linktext="Get Started"
          text="Visiting from Clutch? Let's learn more about your project."
          label="clutch"
        />
        <Layout>
          <Helmet
            title="Versett – A leading consulting firm specialized in digital transformation, working globally, with offices in Canada and Europe."
            meta={[
              {
                name: 'description',
                content:
                  'Versett partners with high-growth companies to design, build, and grow their products. We build competitive advantages through strategy, design, and technology.',
              },
              {
                name: 'keywords',
                content:
                  'versett, design, development, consulting, new product, existing product, new company',
              },
              {
                property: 'og:title',
                content:
                  'Versett – A leading consulting firm specialized in digital transformation, working globally, with offices in Canada and Europe.',
              },
              {
                property: 'og:description',
                content:
                  'Versett partners with high-growth companies to design, build, and grow their products. We build competitive advantages through strategy, design, and technology.',
              },
              {
                property: 'og:image',
                content: 'https://versett.com/images/social-index.jpg',
              },
              { property: 'og:url', content: 'https://versett.com/' },
              { name: 'twitter:card', content: 'summary_large_image' },
              { property: 'og:site_name', content: 'Versett' },
              { name: 'twitter:image:alt', content: 'Versett' },
              { name: 'robots', content: 'noindex, nofollow' },
            ]}
          >
            <html lang="en" className="has-cta" />
          </Helmet>
          <div
            className="t-interstitial"
            style={{
              fill: 'none',
              stroke: 'white',
              backgroundColor: 'black',
              color: 'white',
            }}
          >
            <svg
              viewBox="0 0 436 236"
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              style={{ stroke: 'none', fill: 'white' }}
            >
              <path d="M48.88.89l73.26 170.25L196.43.89h48.5L137.62 235.11h-31L.38.89h48.5zM297.54 1L190.47 235H233L340.11 1h-42.57zm95.51 0L286 235h42.56L435.62 1h-42.57z" />
            </svg>
          </div>
          <section className="s-container t-hero t-hero--front">
            <PageHeader lede="We combine strategy, technology, data science and design to solve the most complex business challenges.">
              <Link className="t-hero__link" to="/thesis/">
                Read Thesis
              </Link>
            </PageHeader>
            <DownArrow />
          </section>
          {/* Use `dangerouslySetInnerHTML` because React wants to strip `muted`. */}
          <Parallax speed={-4} className="t-hero-video">
            <VideoBlock>
              <div
                id="hero-video"
                aria-hidden="true"
                dangerouslySetInnerHTML={{
                  __html: `
                <video autoPlay playsInLine muted loop id="video-background">
                  <source src="/video/v-homepage.mp4" type="video/mp4"/>
                  <track src="/video/v-homepage.vtt" kind="captions" srclang="en" label="captions">
                  <track src="/video/v-homepage.vtt" kind="descriptions" srclang="en" label="captions">
                </video>
              `,
                }}
              />
            </VideoBlock>
          </Parallax>
          <section className="s-section s-container c-services">
            <SectionHeader
              heading="What We Do"
              lede="Our specialized teams lead the product design and development process for high growth companies."
            />
            <Services />
          </section>
          <ColorSwitch />
          <Drift />
          <section
            className={`parent s-section s-container c-client-cards ${
              this.state.showAllClients
                ? 'c-client-cards--show-all-clients'
                : ''
            }`}
          >
            <SectionHeader
              heading="Recent Projects"
              lede="Every month, millions of people use the products we’ve built."
            />
            <div
              id="demo-video"
              aria-hidden="true"
              dangerouslySetInnerHTML={{
                __html: `
              <video autoPlay playsInLine muted loop id="video-demoreel">
                <source src="/video/demo-reel.mp4" type="video/mp4"/>
                <track src="/video/demo-reel.vtt" kind="captions" srclang="en" label="captions">
                <track src="/video/demo-reel.vtt" kind="descriptions" srclang="en" label="captions">
              </video>
            `,
              }}
            />
            <div className="c-filter c-filter--delayed">
              {this.clientFilters.map((item, index) => (
                <button
                  key={index}
                  className={this.state.filter === item ? 'active' : ''}
                  onClick={() => this.setState({ filter: item })}
                >
                  {item}
                </button>
              ))}
            </div>
            {this.renderClients(this.clients)}
          </section>
          <section className="s-section s-container c-client-slider">
            <SectionHeader
              classes="t-section-heading--narrow"
              heading="Client Stories"
              lede="Designing better experiences is a journey. Here are the stories behind the products."
            />
            <Block>
              <div>
                <div className="grid grid--2col-2-1 c-client-story">
                  <div>
                    <img
                      className="c-client-story__img"
                      src="/images/client-story--mast.jpg"
                      alt=""
                    />
                  </div>
                  <div className="c-client-story__body">
                    <div className="c-client-story__cite">
                      <p>
                        Rob Gaspar
                        <span className="color-secondary">Mast Hair</span>
                      </p>
                    </div>
                    <blockquote>
                      <span>
                        Versett has completely reinvented the way that we work
                        across our organization.
                      </span>
                    </blockquote>
                    <div>
                      <Link
                        className="c-client-slider__link"
                        to="/client-stories/"
                      >
                        Read Rob's story
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Block>
          </section>
          <NewsletterSignup>
            <div className="cards grid grid--3col">
              {this.links.map((link, index) => (
                <LinkCard key={index} card={link} />
              ))}
            </div>
          </NewsletterSignup>
          <section className="s-section c-gallery">
            <div className="s-container">
              <SectionHeader
                heading="Offices"
                lede="We work globally and have offices in Canada and Europe.​"
              />
            </div>
            <Block>
              <ImageSlider slides={this.photos} />
            </Block>
          </section>
          <Prefooter />
        </Layout>
      </>
    )
  }
}

export default ClutchPage

export const query = graphql`
  query clutchPagesQueries($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPrismicClients(
      sort: { fields: [data___weight], order: ASC }
      filter: { data: { display_on_front_page: { eq: "Yes" } } }
    ) {
      edges {
        node {
          id
          data {
            name
            lede
            description
            tags
            urls {
              url
              url_text
            }
            company_type
            logo {
              url
            }
          }
        }
      }
    }
    allPrismicRecentLinks {
      edges {
        node {
          data {
            links {
              description
              source
              url
              photo {
                url
                thumbnails {
                  link_300_1x {
                    url
                  }
                  link_300_2x {
                    url
                  }
                  link_500_1x {
                    url
                  }
                  link_500_2x {
                    url
                  }
                  link_700_1x {
                    url
                  }
                  link_700_2x {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicPhotoGallery {
      edges {
        node {
          data {
            photos {
              name
              photo {
                url
              }
            }
          }
        }
      }
    }
  }
`
